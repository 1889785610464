.img {
  display: block;
  position: relative;
  width: 100%;
  height: var(--heroHeight);
}
.topBg {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgb(7,86,136);
  background: linear-gradient(180deg, rgba(7,86,136,0.3) 0%, rgba(255,255,255,0.3) 30%);
}