@import '@/components/variables.scss';

.contents {

  display: block;
  margin-top: calc((var(--heroHeight) / 2 * -1));
  
  @media screen and (max-width: $x-breakpoint-xs) {
    margin-top: calc((var(--heroHeight) * -1) + 88px);
  }
  
}
