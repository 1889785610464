@import '@/components/variables.scss';

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: spacer(xl);

  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
    align-items: center;
  }      

}

.cell {
  position: relative;
  flex: 0 0 auto;
  width: 300px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: spacer(lg);
  align-items: center;
}

.ico {
  display: block;
  height: 84px;
  width: 100%;
  position: relative;
  z-index: 1;
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}

.step {

  &_header {
    flex: 0 0 auto;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
  }

  &_text {
    flex: 0 0 auto;
    font-size: 1rem;
    text-align: center;
  }

}

.num {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -96px;
  border-radius: 100%;
  background-color: var(--primaryColorFaint);
  color: var(--primaryColor);
  width: 64px;
  height: 64px;
  font-size: 1.3rem;
  font-weight: 700;
}