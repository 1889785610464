@import '@/components/variables.scss';



body, main, button, input, textarea, select, a, div, span, nav, header, h1, h2, h3, h4, pre, form {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  box-sizing: border-box;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.doc {
  max-width: 100%;
  white-space: pre-line;  
}
.doc h1 {
  padding-top: spacer(lg);
}
.doc h2 {
  padding-top: spacer(lg);
  padding-bottom: spacer(lg);
}

body, .body-bg {
  background-color: var(--bodyBg);
}

.h2 {
  font-size: 2rem;
}

.h3 {
  font-size: 1.2rem;
}

main, header {

  section {
    
    display: block;
    position: relative;
    box-sizing: border-box;
    padding-bottom: spacer(lg);
    
    @media screen and (min-width: var(--contentMaxWidth)) {
      padding-left: 0;
      padding-right: 0;
    }
    
    @media screen and (max-width: $x-breakpoint-sm) {
      display: block;
      width: 100%;
      padding-left: spacer(lg);
      padding-right: spacer(lg);
    }
    
    @media screen and (max-width: $x-breakpoint-xs) {
      display: block;
      width: 100%;
      padding-left: spacer(lg);
      padding-right: spacer(lg);
    }          
    
    &:last-child {
      padding-bottom: 0;
    }
    
    > section {
      padding-left: 0;
      padding-right: 0;
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }          
    }
    
    &.--nopad {
      @media screen and (max-width: $x-breakpoint-xs) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }          
    }
    
    &.primary {
      background-color: var(--primaryColor);
      border-radius: var(--tileBorderRadius);
      padding: spacer(xl);
      color: white;
    }
    
    &.white {
      background-color: white;
      padding: spacer(xl);
      border-radius: var(--tileBorderRadius);
      @media screen and (max-width: $x-breakpoint-xs) {
        border-radius: 0;
        padding-left: spacer(lg);
        padding-right: spacer(lg);
      }
    }

    &.row {
      padding-top: spacer(base);
      padding-bottom: spacer(base);
    }

    &.hscroll {
      overflow-x: auto;
    }

    &.hidden {
      display: none;
    }
    
  }
  
}

button {
  display: flex;
  flex: 0 0 auto;
  appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  border-width: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

.page {
  display: block;
  position: relative;
  width: 100%;
}

.page-contents {
  display: block;
  position: relative;
  max-width: var(--contentMaxWidth);
  margin: auto;
  width: 100%;

  iframe {
    bordeR: 0;
    width: 100%;
  }

}

.thin-contents {
  display: block;
  position: relative;
  max-width: 1024px;
  margin: auto;
  width: 100%;
}

.fill {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row-column {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-direction: column;
    width: 100% !important;
  }
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-col {
  flex: 0 0 auto;
}
.flex-stretch {
  flex: 1 1 auto;
}
.flex-right {
  text-align: right;
}
.flex-with-gap {
  gap: spacer(base);
}
.flex-with-gap-sm {
  gap: spacer(sm);
}
.flex-with-gap-lg {
  gap: spacer(xl);
}
.flex-with-gap-xl {
  gap: spacer(xxl);
}
.flex-align-center {
  justify-content: center;
}
.flex-align-middle {
  align-items: center;
}
.flex-wrapped {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-full {
  width: 100%;
}
.flex-with-gap {
  .flex-col50 {
    width: calc(50% - (spacer(base)/2));
  }
}
.flex-vscroll {
  overflow-y: auto;
}

.page-bottom-icons {
  display: block;
  padding: spacer(xl);
  background-color: white;
  margin-top: spacer(xl);
  text-align: center;
  img {
    display: inline-block;
    vertical-align: top;
    height: 100px;
    flex: 0 0 auto;
    align-self: center;
    justify-self: center;
  }
}

.display-none {
  display: none;
}
.display-show {
  .flex-column {
    display: flex;
  }
  .flex-col {
    display: block;
  }
}
.relative {
  position: relative;
}

@each $name, $color in $x-colors {
  .bg-#{$name} { background-color: $color !important; }
  .text-#{$name} { color: $color !important; }
}

.ico-smaller {
  i {
    width: 16px !important;
    height: 16px !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
  }
}

.text-bold { font-weight: 700; }
.text-normal { font-weight: 400 !important; }

.text-align-center { text-align: center; }
.text-align-left { text-align: left; }
.text-align-right { text-align: right; }

.text-xs { font-size: .800rem; }
.text-sm { font-size: .875rem; }
.text-md { 
  font-size: 1.25rem !important;
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 1rem !important;
  }
  svg {
    max-width: 44px !important;
    height: 24px !important;
  }
}
.text-lg { font-size: 2rem; }
.text-xl { 
  font-size: 2.5rem !important;
  svg {
    max-width: 64px !important;
    height: 32px !important;
  }
}
.ico-reg {
  svg {
    height: 18px !important;
  }
}
.ico-md { 
  font-size: 1.25rem !important;
  svg {
    width: 48px !important;
    height: 24px !important;
  }
}
.ico-lg { 
  font-size: 2rem !important;
  svg {
    width: 60px !important;
    height: 36px !important;
  }
}
.ico-xl { 
  font-size: 2.5rem !important;
  svg {
    width: 72px !important;
    height: 48px !important;
  }
}

.text-disclaimer {
  font-size: 0.675rem;
  color: #333333;
  text-align: justify;
  font-weight: 400;
}

@each $bp in $x-breakpoints {
  .min-b-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-b-#{$bp} {
      display: block !important;
    }
  }
  .min-i-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .min-f-#{$bp} {
    display: none !important;
  }
  @media screen and (min-width: breakpoint($bp)) {
    .min-f-#{$bp} {
      display: flex !important;
    }
  }
  .max-b-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-b-#{$bp} {
      display: block !important;
    }
  }
  .max-i-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-i-#{$bp} {
      display: inline-block !important;
    }
  }
  .max-f-#{$bp} {
    display: none !important;
  }
  @media screen and (max-width: breakpoint($bp)) {
    .max-f-#{$bp} {
      display: flex !important;
    }
  }
}

.ada-rc {
  #ae_launcher {
    bottom: calc(100vh - 50% - 22px) !important;
    right: 12px !important;
    left: auto !important;
    top: auto !important;
  }
}
.ada-lc {
  #ae_launcher {
    bottom: calc(100vh - 50% - 22px) !important;
    left: 12px !important;
    right: auto !important;
    top: auto !important;
  }
}
.ada-br {
  #ae_launcher {
    bottom: 12px !important;
    right: 12px !important;
    left: auto !important;
    top: auto !important;
  }
}
.ada-bl {
  #ae_launcher {
    bottom: 12px !important;
    left: 12px !important;
    right: auto !important;
    top: auto !important;
  }
}
.blur {
  filter: blur(0.5rem);
}