@import '@/components/variables.scss';

.topBg {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  max-height: 400px;
  background: transparent;
  background: linear-gradient(180deg, var(--topGradientColor) 0%, rgba(255,255,255,0) 40%);
  opacity: 0.5;
}

.body {
  background: transparent;
  background: linear-gradient(180deg, var(--bodyBg) 0%, rgba(255,255,255,0) 30%);
}

.fullBar {
  
  display: block;
  background-color: var(--primaryColor);
  
}