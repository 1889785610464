@import '@/components/variables.scss';

.footer {
  
  padding-top: spacer(xl);
  padding-bottom: spacer(xl);
  background-color: var(--primaryColor);
  float: left;
  width: 100%;
  
  display: block;
  
  > div {
    flex: 0 0 auto;
    width: 100%;
    margin-bottom: spacer(xl)
  }
  
}


.cols {
  
  display: flex;
  flex-direction: row;
  
  padding-left: spacer(xl);
  padding-right: spacer(xl);
    
  @media screen and (max-width: $x-breakpoint-sm) {
    flex-wrap: wrap;
    gap: spacer(lg);
    padding-left: spacer(xl);
    padding-right: spacer(xl);
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    flex-wrap: wrap;
    gap: spacer(lg);
    padding-left: spacer(xl);
    padding-right: spacer(xl);
  }      
}

.col {
  
  flex: 0 0 auto;
  padding-right: 6vw;
  
  &:last-child {
    padding-right: 0;
  }
  
  @media screen and (max-width: $x-breakpoint-sm) {
    width: calc(50% - spacer(lg))
  }
  
  @media screen and (max-width: $x-breakpoint-xs) {
    width: calc(50% - spacer(lg))
  }

  &_full {
    @media screen and (max-width: $x-breakpoint-xs) {
      width: 100% !important;
    }
  }
  
}

.colMid {
  flex: 1 1 auto;
  @media screen and (max-width: $x-breakpoint-sm) {
    display: none;
  }
  @media screen and (max-width: $x-breakpoint-xs) {
    display: none;
  }      
}

.header {
  display: block;
  font-weight: 700;
  font-size: .875rem;
  color: white;
  padding-bottom: spacer(base);
}

.link {
  display: block;
  font-weight: 400;
  font-size: .875rem;
  padding-bottom: spacer(base);
  color: white;
  &:last-child {
    padding-bottom: 0;
  }
}

.divider {
  border-top: 1px solid rgba(255,255,255,0.4);
  display: block;
  margin-top: spacer(xl);
}

.bottom {
  
  display: block;
  font-size: .675rem;
  color: white;
  
  > div {
    display: block;
    padding-bottom: spacer(xs)
  }
  
  a {
    color: white;
    text-decoration: none;
  }
  
}