@import '@/components/variables.scss';

.hero {
  display: block;
  position: absolute;
  width: 100%;
  height: var(--heroHeight);
  
  @media screen and (max-width: $x-breakpoint-xs) {
    height: var(--heroHeightMobile);
  }
  
}

.block {
  position: relative;  
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 144px);
  width: 100%;
  position: absolute;
  > div {
    flex: 0 0 auto;
  }
}

.messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  height: 600px;
  height: var(--heroMsgHeight);
  @media screen and (max-width: $x-breakpoint-xs) {
    height: var(--heroMsgHeightMobile);
  }
  > div {
    display: block;
    margin: 0 auto spacer(xl) auto;
    width: 80%;
    max-width: 880px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.small {
  font-size: 1.7rem;
  line-height: 1.9rem;
  font-weight: 700;
  color: var(--primaryColor);
  text-shadow: 0 0 10px rgba(255,255,255,0.5);
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
}
.medium {
  font-size: 2.8rem;
  line-height: 2.8rem;
  font-weight: 700;
  color: var(--primaryColor);
  text-shadow: 0 0 10px rgba(255,255,255,0.5);
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
}
.large {
  font-size: 4.4rem;
  font-weight: 700;
  line-height: 4.4rem;
  color: var(--primaryColor);
  text-shadow: 0 0 10px rgba(255,255,255,0.5);
  @media screen and (max-width: $x-breakpoint-xs) {
    font-size: 3.2rem;
    line-height: 3.2rem;
  }
}

.reg {
  height: 240px;
}