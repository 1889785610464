@import '@/components/variables.scss';

.topBg {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: var(--headerHeight);
  height: 100%;
  max-height: 400px;
  background: transparent;
  background: linear-gradient(180deg, var(--topGradientColor) 0%, rgba(255,255,255,0) 40%);
  opacity: 0.5;

  &-body {
    background: transparent;
    background: linear-gradient(180deg, var(--bodyBg) 0%, rgba(255,255,255,0) 30%);
    opacity: 1;
  }
  
}

.cb {
  position: fixed;
  display: block;
  bottom: spacer(xl);
  right: spacer(xl);
  left: spacer(xl);
  z-index: 2147483646 !important;
  > div {
    display: flex;
    flex-direction: row;
    gap: spacer(xl);
    align-items: center;
    border-radius: var(--tileBorderRadius);
    background-color: white;
    padding: spacer(xl);
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    max-width: 960px;
    margin: auto;
    button {
      width: 160px;
    }
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: column;
      button {
        width: auto;
      }
    }
  }

  &b {
    display: flex;
    flex-direction: column;
    gap: spacer(base);
    @media screen and (max-width: $x-breakpoint-xs) {
      flex-direction: row;
    }
  }
}

.cs {
  @media screen and (min-width: $x-breakpoint-lg) {
    padding-left: 1rem !important;
  }
}