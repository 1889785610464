@import '@/components/variables.scss';

.contents {

  display: block;
  position: relative;
  
}

.inv {
  display: block;
  padding-top: spacer(xl);
}